@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@300;400;700&display=swap);
.NotificationMessages {
  position: fixed;
  top: 135px;
  width: 100%;
  height: 0 !important;
  z-index: 2000;
  text-align: center; }
  .NotificationMessages > .message {
    display: inline-block;
    min-width: 400px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: opacity 1s; }
    @media (max-width: 575.98px) {
      .NotificationMessages > .message {
        min-width: unset;
        width: 100%; } }
    .NotificationMessages > .message.success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6; }
    .NotificationMessages > .message.info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1; }
    .NotificationMessages > .message.warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc; }
    .NotificationMessages > .message.error {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1; }
    .NotificationMessages > .message.close {
      opacity: 0; }

.loader {
  display: inline-block;
  position: relative; }
  .loader.center {
    width: 100%;
    height: 100%; }
    .loader.center > .inner {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

@-webkit-keyframes move {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes move {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .loader > .inner div:first-of-type {
    background: url(/static/media/ic_progress_shadow.044a731a.png) no-repeat;
    background-size: cover; }
  .loader > .inner div:last-of-type {
    position: absolute;
    background: url(/static/media/ic_progress.abe99038.png) no-repeat;
    background-size: cover;
    -webkit-animation: move linear 2s infinite;
            animation: move linear 2s infinite; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.form {
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767.98px) {
    .form {
      width: 100%; } }
  .form > .tabs {
    display: flex; }
    .form > .tabs > button {
      flex: 1 1;
      height: 75px;
      color: #12566d;
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
      transition: opacity 0.25s;
      background-color: rgba(255, 255, 255, 0.4); }
      .form > .tabs > button.active {
        background-color: white;
        font-weight: 700; }
      .form > .tabs > button:not(.active):hover {
        opacity: 0.5; }
  .form > form > .tab {
    display: none;
    background-color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .form > form > .tab.active {
      display: block; }
    .form > form > .tab > .row {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row; }
      @media (max-width: 575.98px) {
        .form > form > .tab > .row {
          flex-direction: column; } }
      .form > form > .tab > .row > fieldset {
        width: 100%; }
        .form > form > .tab > .row > fieldset.full {
          width: 100%; }
        .form > form > .tab > .row > fieldset input,
        .form > form > .tab > .row > fieldset select,
        .form > form > .tab > .row > fieldset output {
          max-width: 480px;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 16px;
          letter-spacing: 0.3px;
          text-transform: uppercase; }
        @media (max-width: 767.98px) {
          .form > form > .tab > .row > fieldset {
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            flex-wrap: wrap; }
            .form > form > .tab > .row > fieldset > label {
              flex: 1 1 auto; } }
        @media (max-width: 575.98px) {
          .form > form > .tab > .row > fieldset {
            margin-left: 0;
            margin-right: 0; }
            .form > form > .tab > .row > fieldset:not(:first-child) {
              margin-top: 30px; } }
        .form > form > .tab > .row > fieldset > legend {
          margin: 10px 0 10px 5px;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px; }
        .form > form > .tab > .row > fieldset label {
          position: relative;
          margin-right: 10px; }
          .form > form > .tab > .row > fieldset label:last-of-type {
            margin-right: 0; }
          .form > form > .tab > .row > fieldset label > input[type='radio'],
          .form > form > .tab > .row > fieldset label > input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            opacity: 0; }
            .form > form > .tab > .row > fieldset label > input[type='radio'] + span,
            .form > form > .tab > .row > fieldset label > input[type='checkbox'] + span {
              display: inline-block;
              min-width: 185px;
              padding: 0 30px;
              height: 60px;
              cursor: pointer;
              line-height: 60px;
              text-align: center;
              text-transform: uppercase;
              border: 2px solid #808080;
              border-radius: 32px;
              background: #fff;
              font-family: 'Montserrat';
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              letter-spacing: 0.3px;
              text-transform: uppercase;
              color: #bfbfbf;
              transition: background-color 0.25s, color 0.25s; }
              .form > form > .tab > .row > fieldset label > input[type='radio'] + span:hover,
              .form > form > .tab > .row > fieldset label > input[type='checkbox'] + span:hover {
                opacity: 0.75; }
              @media (max-width: 767.98px) {
                .form > form > .tab > .row > fieldset label > input[type='radio'] + span,
                .form > form > .tab > .row > fieldset label > input[type='checkbox'] + span {
                  width: 100%;
                  margin-bottom: 10px; } }
            .form > form > .tab > .row > fieldset label > input[type='radio']:checked:checked + span, .form > form > .tab > .row > fieldset label > input[type='radio']:hover:checked + span,
            .form > form > .tab > .row > fieldset label > input[type='checkbox']:checked:checked + span,
            .form > form > .tab > .row > fieldset label > input[type='checkbox']:hover:checked + span {
              opacity: 1; }
            .form > form > .tab > .row > fieldset label > input[type='radio']:checked + span, .form > form > .tab > .row > fieldset label > input[type='radio']:hover + span,
            .form > form > .tab > .row > fieldset label > input[type='checkbox']:checked + span,
            .form > form > .tab > .row > fieldset label > input[type='checkbox']:hover + span {
              color: #000;
              border: 2px solid #fd8d37; }
          .form > form > .tab > .row > fieldset label > select {
            height: 60px;
            cursor: pointer; }
          .form > form > .tab > .row > fieldset label > input[type='number'],
          .form > form > .tab > .row > fieldset label > input[type='text'],
          .form > form > .tab > .row > fieldset label > input[type='password'],
          .form > form > .tab > .row > fieldset label > input[type='email'] {
            height: 60px;
            text-transform: uppercase;
            border: 2px solid #808080;
            border-radius: 32px; }
            .form > form > .tab > .row > fieldset label > input[type='number'] + .measure,
            .form > form > .tab > .row > fieldset label > input[type='text'] + .measure,
            .form > form > .tab > .row > fieldset label > input[type='password'] + .measure,
            .form > form > .tab > .row > fieldset label > input[type='email'] + .measure {
              position: absolute;
              top: 2px;
              right: 10px;
              color: #505050;
              text-transform: uppercase; }
              @media (max-width: 767.98px) {
                .form > form > .tab > .row > fieldset label > input[type='number'] + .measure,
                .form > form > .tab > .row > fieldset label > input[type='text'] + .measure,
                .form > form > .tab > .row > fieldset label > input[type='password'] + .measure,
                .form > form > .tab > .row > fieldset label > input[type='email'] + .measure {
                  top: 34%; } }
          .form > form > .tab > .row > fieldset label select[name='country_id'][disabled] {
            opacity: 0.5; }
          .form > form > .tab > .row > fieldset label .error-message {
            display: inline-block;
            padding-top: 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.3px;
            text-align: left;
            color: #f0566d; }
    .form > form > .tab > .LoaderWrapper {
      padding-top: 30px; }
      .form > form > .tab > .LoaderWrapper > input {
        cursor: pointer; }
  .form > form > .LoaderWrapper {
    margin: 20px auto; }
  .form .grid-row {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px; }

/* Custom reset */
* {
  box-sizing: border-box; }

.App {
  overflow-y: scroll; }

html,
body,
#root,
.App {
  height: 100%; }

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Montserrat', sans-serif;
  background: #fff; }

#root {
  position: relative; }

a {
  text-decoration: none; }

button {
  font-family: 'Lato', sans-serif;
  border-radius: 0;
  margin: 0;
  padding: 0;
  border-width: 0;
  cursor: pointer;
  background-color: transparent; }

select,
textarea,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0; }

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px white; }

input:required {
  box-shadow: none; }

input[disabled] {
  opacity: 0.5; }

:focus {
  outline-color: #12566d; }

/* Basic component styles */
.innerContainer {
  margin: 0 auto;
  min-height: 100%;
  max-width: 100%; }

.baseButton {
  display: inline-block;
  padding: 12px 30px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  outline: none; }
  @media (max-width: 767.98px) {
    .baseButton {
      height: auto;
      line-height: 1em;
      padding: 20px; } }
  .baseButton-light {
    color: #12566d;
    background-color: #fff; }
  .baseButton-grey {
    color: #12566d;
    background-color: #eceff1; }
  .baseButton-dark {
    color: #ffffff;
    background-color: #FD8D37; }

.baseButton-orange-inverse {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fd8d37;
  background-color: #fff;
  border: 1px solid #fd8d37; }

.baseButton svg {
  margin-left: 10px; }

.registerButton-container .baseButton-orange {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #fd8d37;
  border: 1px solid #fd8d37; }

.baseButton.sendEmail {
  font-size: 12px;
  line-height: 20px;
  min-height: 60px;
  padding: 12px 20px; }

/* Smaller React component styles */
.AppHeader {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: 70px;
  overflow: hidden;
  background: #fff; }
  .AppHeader > .innerContainer {
    position: relative;
    z-index: 1;
    height: 100%; }
    .AppHeader > .innerContainer .back {
      position: absolute;
      left: 0;
      top: 50%;
      padding-left: 5px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .AppHeader > .innerContainer .back > a > svg,
      .AppHeader > .innerContainer .back button > svg {
        width: 46px;
        height: 46px;
        fill: #000;
        transition: -webkit-transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s; }
        .AppHeader > .innerContainer .back > a > svg:hover,
        .AppHeader > .innerContainer .back button > svg:hover {
          -webkit-transform: scale(1.15);
                  transform: scale(1.15); }
    .AppHeader > .innerContainer .title {
      display: inline-block;
      flex: 1 1;
      padding: 0 46px;
      color: #fff;
      line-height: 70px;
      text-align: center;
      text-transform: uppercase; }

.LoaderWrapper {
  position: relative;
  text-align: center; }

.Scrollable .scrollTop {
  position: fixed;
  top: 0;
  z-index: 10;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s; }
  .Scrollable .scrollTop:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }

.Scrollable .scrollDown {
  position: fixed;
  bottom: 0;
  z-index: 10;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s; }
  .Scrollable .scrollDown:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }

.appbar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 2111;
  position: fixed;
  top: 0;
  left: 0; }
  @media (max-width: 991.98px) {
    .appbar {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); } }

.container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  position: relative; }

.page {
  display: flex;
  min-height: 100%;
  padding-top: 80px;
  position: relative;
  max-width: 1024px;
  overflow: hidden; }
  @media (max-width: 1023.98px) {
    .page {
      flex-direction: column; } }
  .page-wrapper {
    width: 100%;
    padding: 20px 0 20px 276px;
    flex-grow: 1; }
    @media (max-width: 1023.98px) {
      .page-wrapper {
        padding: 20px 0 20px 0; } }

.sidebar {
  max-width: 226px;
  width: 100%;
  height: 100%;
  height: calc(100% - 80px);
  position: fixed;
  padding: 20px 30px 20px 0; }
  .sidebar::before {
    content: ' ';
    position: absolute;
    width: 100vw;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(90deg, #b3cdd1 0%, #d5edf1 100%); }
  @media (max-width: 1023.98px) {
    .sidebar {
      position: relative;
      display: none; }
      .sidebar::before {
        content: none; } }

.burger-menu {
  display: flex;
  align-items: center;
  background: #fff; }

.HomeMyProgram {
  position: relative;
  height: 100%; }
  @media (max-width: 767.98px) {
    .HomeMyProgram {
      margin-top: 20px; } }
  @media (max-width: 991.98px) {
    .HomeMyProgram > .baseButton {
      margin-bottom: 40px;
      width: 100%; } }
  .HomeMyProgram > .stats {
    position: relative; }
    @media (max-width: 991.98px) {
      .HomeMyProgram > .stats {
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .HomeMyProgram > .stats {
        display: flex;
        flex-direction: column; } }
    .HomeMyProgram > .stats > .doneExercises {
      margin-bottom: 10px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #333333; }
      .HomeMyProgram > .stats > .doneExercises > span {
        padding-left: 5px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25em;
        color: #333333; }
    .HomeMyProgram > .stats > .progress {
      position: relative;
      width: 310px;
      height: 23px;
      border-radius: 15px;
      overflow: hidden; }
      @media (max-width: 575.98px) {
        .HomeMyProgram > .stats > .progress {
          width: 100%; } }
      .HomeMyProgram > .stats > .progress:before {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        content: ' ';
        background-color: #B6D0D4;
        opacity: 0.2;
        border-radius: 15px; }
      .HomeMyProgram > .stats > .progress > span {
        display: block;
        height: 100%;
        background-color: #B6D0D4; }
    .HomeMyProgram > .stats > button {
      position: absolute;
      bottom: 0;
      right: 0; }
      @media (max-width: 575.98px) {
        .HomeMyProgram > .stats > button {
          position: relative;
          margin-top: 10px;
          align-self: flex-end; } }
  .HomeMyProgram > .exerciseList {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    align-content: baseline;
    padding: 10px 0px 30px 0px; }
    .HomeMyProgram > .exerciseList .addExercise {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
      padding: 20px;
      width: calc(50% - 10px);
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 32px;
      border: 1px solid #fd8d37; }
      .HomeMyProgram > .exerciseList .addExercise .add-icon {
        width: 60px;
        height: 60px;
        background: #fd8d37;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
      @media (max-width: 1199.98px) {
        .HomeMyProgram > .exerciseList .addExercise {
          width: 100%; } }
      .HomeMyProgram > .exerciseList .addExercise > svg {
        margin-bottom: 15px;
        width: 48px;
        height: 48px;
        fill: currentColor; }
    .HomeMyProgram > .exerciseList .HomeExerciseProgram {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: calc(50% - 10px);
      color: #12566d;
      transition: -webkit-transform 0.25s;
      transition: transform 0.25s;
      transition: transform 0.25s, -webkit-transform 0.25s;
      background: linear-gradient(180deg, #d5edf1 0%, #b5cfd3 100%);
      border-radius: 32px; }
      @media (max-width: 1199.98px) {
        .HomeMyProgram > .exerciseList .HomeExerciseProgram {
          width: 100%; } }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive {
        background: linear-gradient(0deg, #fd8d37, #fd8d37), linear-gradient(180deg, #d5edf1 0%, #b5cfd3 100%); }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive h1,
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive h2,
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive li,
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive div,
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive span {
          color: #fff; }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive:nth-of-type(odd), .HomeMyProgram > .exerciseList .HomeExerciseProgram:hover:nth-of-type(odd) {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(1.025) translateX(-5px);
                transform: scale(1.025) translateX(-5px); }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram.isActive:nth-of-type(even), .HomeMyProgram > .exerciseList .HomeExerciseProgram:hover:nth-of-type(even) {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(1.025) translateX(5px);
                transform: scale(1.025) translateX(5px); }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram.isDisabled {
        opacity: 0.5; }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram.isFinished {
        background-color: rgba(250, 255, 243, 0.2); }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram.isFinished > a {
          cursor: default; }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram > a {
        padding: 30px;
        color: #12566d;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats {
          position: absolute;
          top: 20px;
          right: 20px; }
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round {
            position: relative;
            margin-bottom: 10px;
            padding: 5px;
            width: 80px;
            height: 80px;
            background-size: contain;
            border: 3px solid #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 35px;
            color: #ffffff; }
            .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round .gauge {
              position: absolute;
              width: 90px;
              height: 90px;
              top: -8px;
              left: -8px;
              border-width: 3px;
              border-style: solid;
              border-radius: 50%;
              border-color: #B6D0D4; }
              .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round .gauge.completed {
                border-color: #9eda51; }
              .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round .gauge.inProgress {
                border-color: #FD8D37; }
            .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round > .check {
              stroke-width: 1px;
              stroke: #9eda51;
              width: 50px;
              height: 50px;
              fill: #9eda51; }
            @media (max-width: 575.98px) {
              .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round {
                width: 70px;
                height: 70px;
                font-size: 24px; }
                .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > .round > .gauge {
                  width: 80px;
                  height: 80px; } }
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > span {
            display: block;
            text-align: center;
            text-transform: uppercase;
            color: #B6D0D4;
            font-size: 14px; }
            .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > span.completed {
              color: #9eda51; }
            .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > span.inProgress {
              color: #FD8D37; }
            @media (max-width: 575.98px) {
              .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .stats > span {
                font-size: 12px; } }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .updateButton,
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .deleteButton {
          position: absolute;
          right: 30px;
          color: rgba(18, 86, 109, 0.8);
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .updateButton {
          top: 20px; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .deleteButton {
          bottom: 20px; }
        @media (max-width: 767.98px) {
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .updateButton,
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a .deleteButton {
            right: 10px;
            font-size: 12px; } }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > h1 {
          margin-bottom: 5px;
          font-family: 'Merriweather';
          font-style: italic;
          font-weight: 400;
          font-size: 24px;
          line-height: 30px;
          color: #333333; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > h2 {
          margin-bottom: 30px;
          max-width: 200px;
          width: 100%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #333333; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .details > ul {
          width: 200px; }
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .details > ul > li {
            margin-bottom: 5px;
            display: block;
            color: #12566d;
            font-size: 16px;
            font-weight: 300; }
        @media (max-width: 575.98px) {
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > h1 {
            font-size: 16px; }
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > h2 {
            font-size: 14px;
            max-width: 180px; }
          .HomeMyProgram > .exerciseList .HomeExerciseProgram > a > .details > ul > li {
            font-size: 14px; } }
      .HomeMyProgram > .exerciseList .HomeExerciseProgram > .arrowBottom {
        position: relative;
        flex: 1 1; }
        .HomeMyProgram > .exerciseList .HomeExerciseProgram > .arrowBottom:before {
          content: ' ';
          position: absolute;
          bottom: -34px;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 0 15px;
          border-color: #ffffff transparent transparent transparent; }
    .HomeMyProgram > .exerciseList .HomeExercise {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%; }
      @media (max-width: 767.98px) {
        .HomeMyProgram > .exerciseList .HomeExercise {
          flex-direction: column; } }
      .HomeMyProgram > .exerciseList .HomeExercise > button {
        position: relative;
        padding: 30px;
        width: calc(50% - 10px);
        height: 232px;
        color: #333333;
        text-align: left;
        border-radius: 32px;
        display: flex;
        align-items: center; }
        @media (max-width: 767.98px) {
          .HomeMyProgram > .exerciseList .HomeExercise > button {
            padding: 10px;
            width: 100%; }
            .HomeMyProgram > .exerciseList .HomeExercise > button:not(:first-child) {
              margin-top: 20px; } }
        .HomeMyProgram > .exerciseList .HomeExercise > button.stretching {
          border: 1px solid #e86869; }
          .HomeMyProgram > .exerciseList .HomeExercise > button.stretching .play {
            background: #e86869; }
          .HomeMyProgram > .exerciseList .HomeExercise > button.stretching > .play > svg {
            fill: #fff; }
        .HomeMyProgram > .exerciseList .HomeExercise > button.training {
          border: 1px solid #cd8bb7; }
          .HomeMyProgram > .exerciseList .HomeExercise > button.training .play {
            background: #cd8bb7; }
          .HomeMyProgram > .exerciseList .HomeExercise > button.training > .play > svg {
            fill: #fff; }
        .HomeMyProgram > .exerciseList .HomeExercise > button > h1 {
          margin-bottom: 5px;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25em;
          text-transform: uppercase;
          color: #333333; }
          @media (max-width: 991.98px) {
            .HomeMyProgram > .exerciseList .HomeExercise > button > h1 {
              font-size: 20px; } }
        .HomeMyProgram > .exerciseList .HomeExercise > button > h2 {
          margin-bottom: 65px;
          padding-right: 100px;
          font-size: 16px;
          font-weight: 300;
          text-transform: uppercase; }
          @media (max-width: 991.98px) {
            .HomeMyProgram > .exerciseList .HomeExercise > button > h2 {
              font-size: 14px;
              margin-bottom: 40px;
              padding-right: 50px; } }
        .HomeMyProgram > .exerciseList .HomeExercise > button > .play {
          padding-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          background-color: #fff;
          border-radius: 50%;
          margin-right: 20px;
          flex-shrink: 0; }
          .HomeMyProgram > .exerciseList .HomeExercise > button > .play > svg {
            width: 50px;
            height: 50px; }
          @media (max-width: 991.98px) {
            .HomeMyProgram > .exerciseList .HomeExercise > button > .play {
              width: 50px;
              height: 50px; }
              .HomeMyProgram > .exerciseList .HomeExercise > button > .play > svg {
                width: 30px;
                height: 30px; } }
        .HomeMyProgram > .exerciseList .HomeExercise > button > .duration {
          font-size: 22px;
          font-weight: 300; }
          @media (max-width: 991.98px) {
            .HomeMyProgram > .exerciseList .HomeExercise > button > .duration {
              display: block; } }
          .HomeMyProgram > .exerciseList .HomeExercise > button > .duration > svg {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            vertical-align: bottom;
            fill: #ffffff; }
          .HomeMyProgram > .exerciseList .HomeExercise > button > .duration > span > span {
            font-weight: 100;
            font-size: 28px; }

.HomeProfile {
  margin-bottom: 40px; }
  .HomeProfile > div {
    padding: 20px;
    text-align: center;
    color: #333333;
    background: #ffffff;
    border-radius: 26px; }
    .HomeProfile > div > svg {
      fill: #B6D0D4;
      width: 46px;
      height: 46px; }
    .HomeProfile > div > p {
      text-transform: uppercase;
      font-size: 14px;
      padding: 5px; }
    .HomeProfile > div > a {
      display: inline-block;
      margin-top: 20px;
      font-size: 15px;
      font-weight: 700;
      color: #12566d;
      text-transform: uppercase; }
      .HomeProfile > div > a:hover {
        text-decoration: underline; }

.HomeMyFollowUp > div {
  position: relative;
  max-height: 400px;
  background: #ffffff;
  border-radius: 26px;
  padding: 20px;
  width: 100%; }
  .HomeMyFollowUp > div > div {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    color: #333333; }
    .HomeMyFollowUp > div > div:before {
      content: '%';
      position: absolute;
      top: 25px;
      left: 15px;
      font-size: 13px; }
    .HomeMyFollowUp > div > div > a {
      display: inline-block;
      margin-top: 20px;
      font-size: 15px;
      font-weight: 700;
      color: #12566d;
      text-transform: uppercase; }
      .HomeMyFollowUp > div > div > a:hover {
        text-decoration: underline; }

.HomeModal > .innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .HomeModal > .innerContainer > h1 {
    margin: 0 auto;
    width: 50%;
    line-height: 1.3em; }
    @media (max-width: 575.98px) {
      .HomeModal > .innerContainer > h1 {
        width: 100%;
        padding: 0 20px;
        line-height: 1.1em; } }
  .HomeModal > .innerContainer > .modalContent {
    padding: 15% 0;
    color: #ffffff;
    text-align: center; }
    @media (max-width: 575.98px) {
      .HomeModal > .innerContainer > .modalContent {
        padding: 10% 5%; } }
    .HomeModal > .innerContainer > .modalContent > .FormCounter {
      padding: 0 20px; }
      @media (max-width: 575.98px) {
        .HomeModal > .innerContainer > .modalContent > .FormCounter {
          padding: 10px 20px; } }
      .HomeModal > .innerContainer > .modalContent > .FormCounter > .value {
        color: #ffffff;
        font-weight: 300; }
      .HomeModal > .innerContainer > .modalContent > .FormCounter > button {
        background-color: #fff; }
  .HomeModal > .innerContainer > .actions {
    margin-top: 0; }
  .HomeModal > .innerContainer > .buttonContainers {
    text-align: center; }
    .HomeModal > .innerContainer > .buttonContainers > a {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 30px;
      padding: 40px 20px 20px;
      width: 320px;
      height: 320px;
      background-color: #fff;
      border-radius: 50%;
      text-transform: uppercase;
      text-align: center;
      border: 5px solid #B6D0D4;
      overflow: hidden;
      color: #B6D0D4;
      transition: -webkit-transform 0.25s;
      transition: transform 0.25s;
      transition: transform 0.25s, -webkit-transform 0.25s; }
      .HomeModal > .innerContainer > .buttonContainers > a:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
      @media (max-width: 767.98px) {
        .HomeModal > .innerContainer > .buttonContainers > a {
          width: 240px;
          height: 240px;
          margin: 20px;
          font-size: 14px; } }
      .HomeModal > .innerContainer > .buttonContainers > a > img {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        margin-bottom: 20px; }

.toggleMenu {
  height: 100%; }
  .toggleMenu > button {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: transparent;
    border: none;
    color: #000; }
    .toggleMenu > button > svg {
      width: 46px;
      height: 46px;
      fill: #000;
      transition: -webkit-transform 0.25s;
      transition: transform 0.25s;
      transition: transform 0.25s, -webkit-transform 0.25s; }
      .toggleMenu > button > svg:hover {
        -webkit-transform: scale(1.15);
                transform: scale(1.15); }

.exercise-stats {
  display: flex;
  align-items: center;
  margin-top: 20px; }
  .exercise-stats__counter {
    height: 48px;
    background: #ffffff;
    border-radius: 32px;
    padding: 15px 45px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #333333; }
  .exercise-stats__reps {
    margin-left: 16px; }

.policy__link {
  font-size: 14px;
  text-decoration: none;
  display: flex;
  color: #525151;
  justify-content: center; }

.exercise-details {
  display: flex;
  flex-grow: 1; }
  .exercise-details__list {
    width: auto !important; }
    .exercise-details__list-item {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #333333 !important; }
  .exercise-details__image {
    position: relative;
    overflow: hidden;
    max-width: 100px;
    margin-right: 20px;
    width: 100%;
    height: 90px;
    border-radius: 15px;
    background: #fff; }
    .exercise-details__image img {
      max-width: 100%;
      object-fit: cover;
      margin: 0 auto;
      display: block;
      max-height: 100%; }

.MyIdentifiersForm {
  text-align: center;
  margin-bottom: 15px; }
  .MyIdentifiersForm fieldset label {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .MyIdentifiersForm fieldset label input {
      align-self: center; }

.CitySelect {
  max-width: 480px;
  outline: none !important; }

.CitySelect .Select-control {
  max-width: 480px;
  padding: 0 0 0 20px;
  width: 100%;
  height: 60px;
  border: 2px solid #808080 !important;
  border-radius: 32px;
  outline: none !important; }

.CitySelect .Select-placeholder,
.CitySelect .Select-value-label {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 60px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #505050;
  padding: 0 0 0 10px; }

.CitySelect .Select-input {
  height: 60px;
  line-height: 60px; }

.footer__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

@media (max-width: 1024px) {
  .footer__main {
    flex-direction: column; } }

.footer__block {
  max-width: 600px;
  min-width: 552px;
  margin-bottom: 60px; }

@media (max-width: 768px) {
  .footer__block {
    min-width: 100px; } }

@media (max-width: 1240px) {
  .footer__block {
    max-width: 450px; } }

@media (max-width: 1024px) {
  .footer__block {
    max-width: unset;
    margin-bottom: 48px; } }

.footer__link,
.footer__text {
  display: block;
  line-height: 18px;
  letter-spacing: 0.3px;
  font-size: 14px;
  text-decoration: none;
  color: #525252; }

.footer__text.footer__text_right {
  display: inline;
  line-height: 18px;
  color: #8c97a1; }

.footer_bottom_left {
  display: flex;
  align-items: center;
  margin-bottom: 0; }

.footer_bottom_right {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.footer_bottom_left-title {
  color: #27556b;
  font-size: 36px;
  font-weight: 700; }

.footer_bottom_left-subtitle {
  font-size: 14px;
  color: #8c97a1;
  margin-left: 16px; }

.footer_bottom_left-subtitle span {
  display: block; }

.footer__link_footer {
  margin-bottom: 0; }

@media (max-width: 768px) {
  .footer__link,
  .footer__text {
    font-size: 12px; } }

.footer__link {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.footer__link span {
  padding-left: 7px; }

.footer__link--line {
  display: inline-block; }

.footer__link:after {
  position: absolute;
  display: block;
  content: '';
  bottom: 1px;
  left: 0;
  height: 1px;
  width: 0;
  background: #333;
  opacity: 0;
  transition: width 0.3s, opacity 0.3s; }

.footer__link:hover:after {
  width: 100%;
  opacity: 1; }

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap; }

@media (max-width: 1100px) {
  .footer_bottom_left {
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer_bottom_right {
    width: 100%;
    justify-content: space-between; } }

@media (max-width: 530px) {
  .footer_bottom_left-subtitle {
    margin-left: 0;
    margin-top: 10px; }
  .footer_bottom_right {
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px; } }

